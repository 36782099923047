import React, { useState } from 'react';
import { Box } from '@mui/material';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
import { Text as DTText } from '@dt/material-components';
import { truncateNumber } from '@utils/truncateNumber';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { Pie } from '@visx/shape';
import { Text } from '@visx/text';
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import AnimatedPie from '../AnimatedPie';
const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
};
const defaultMargin = { bottom: 20, left: 20, right: 20, top: 20 };
const PieChart = ({ shouldHideTooltip, width, height, margin, data, text, subText, smallText = false, textWidth, isEmptyState = false, }) => {
    const [activeArc, setActiveArc] = useState(null);
    const { tooltipOpen, tooltipLeft, tooltipTop = 0, tooltipData, hideTooltip, showTooltip, } = useTooltip();
    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        scroll: true,
    });
    const pieMargin = margin || defaultMargin;
    const innerWidth = width - pieMargin.left - pieMargin.right;
    const innerHeight = height - pieMargin.top - pieMargin.bottom;
    const isSmall = innerWidth < 75;
    const radius = Math.min(innerWidth, innerHeight) / 2;
    const centerY = innerHeight / 2;
    const centerX = innerWidth / 2;
    const donutThickness = isSmall ? 8 : 15;
    const usage = (d) => d.value;
    const handleTooltip = (arc) => (event) => {
        if (isEmptyState)
            return;
        const { x } = localPoint(event) || { x: 0 };
        const { y } = localPoint(event) || { y: 0 };
        showTooltip({
            tooltipData: arc,
            tooltipLeft: x,
            tooltipTop: y,
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { height: height, ref: containerRef, width: width },
            React.createElement(Group, { left: centerX + pieMargin.left, top: centerY + pieMargin.top },
                React.createElement(Pie, { cornerRadius: 3, data: data, innerRadius: data => {
                        const updatedRadius = radius - donutThickness;
                        if (data.data.name === activeArc?.name) {
                            return updatedRadius - (isSmall ? 1 : 3);
                        }
                        return updatedRadius;
                    }, outerRadius: data => {
                        const updatedRadius = radius;
                        if (data.data.name === activeArc?.name) {
                            return updatedRadius + (isSmall ? 1 : 3);
                        }
                        return updatedRadius;
                    }, padAngle: 0.01, pieValue: usage }, pie => (React.createElement(AnimatedPie, { ...pie, getColor: d => d.data.color, getKey: arc => arc.data.name, onClickDatum: () => { }, onMouseLeave: () => () => {
                        hideTooltip();
                    }, onMouseMove: handleTooltip, onTouchMove: handleTooltip, onTouchStart: handleTooltip, setActiveArc: setActiveArc }))),
                React.createElement(Text, { className: smallText ? 'text-helperText' : 'text-titleL', dy: -10, fontSize: 20, textAnchor: "middle", verticalAnchor: "middle", width: textWidth }, text),
                React.createElement(Text, { className: "text-helperText", dy: text ? 10 : 0, fontSize: 20, textAnchor: "middle", verticalAnchor: "middle", width: textWidth }, subText))),
        !shouldHideTooltip && tooltipOpen && tooltipData && (React.createElement(TooltipInPortal, { left: tooltipLeft, style: tooltipStyles, top: tooltipTop },
            React.createElement(Box, { alignItems: "center", display: "flex" },
                React.createElement(Box, { style: {
                        backgroundColor: tooltipData.color,
                        display: 'inline-block',
                        height: 10,
                        marginRight: 5,
                        width: 10,
                    } }),
                React.createElement(DTText, { component: "div", variant: "body" },
                    truncateNumber(tooltipData.value),
                    " ",
                    tooltipData.name))))));
};
export default PieChart;
