import React from 'react';
import { Box, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Text from '../../../Text';
const TableFiltersDateRange = () => {
    return (React.createElement(Stack, { direction: "column", gap: 4, padding: 2 },
        React.createElement(Box, null,
            React.createElement(Text, { variant: "body" }, "From:"),
            React.createElement(DatePicker
            // // onError={newError => setErrorDate(newError)}
            , { 
                // // onError={newError => setErrorDate(newError)}
                format: "MM-DD-YYYY", label: "mm/dd/yyyy" })),
        React.createElement(Box, null,
            React.createElement(Text, { variant: "body" }, "To:"),
            React.createElement(DatePicker, { format: "MM-DD-YYYY", label: "mm/dd/yyyy" }))));
};
export default TableFiltersDateRange;
